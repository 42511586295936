import React from "react";
import {
  HiHome,
  HiMenuAlt1,
  HiUser,
  HiMenu,
  HiUsers,
  HiSupport,
  HiOutlineClipboardCheck,
} from "react-icons/hi";
import { FaSkype } from "react-icons/fa";
import { Link, Outlet } from "react-router-dom";
import { useState } from "react";
import Header from "../Pages/Shared/Header/Header";
import Footer from "../Pages/Shared/Footer/Footer";
import { useContext } from "react";
import { AuthContext } from "../Contexts/AuthProvider";
import useAdmin from "../hooks/useAdmin";
import { useEffect } from "react";
import Pusher from "pusher-js";
import { toast } from "react-hot-toast";

const DashboardLayout = () => {
  const { user } = useContext(AuthContext);
  const [isAdmin] = useAdmin(user?.email);
  const [open, setOpen] = useState(true);

  const menus = [
    { name: "Dashboard", link: "/dashboard", icon: <HiHome></HiHome> },
    {
      name: "Website",
      link: "/dashboard/website",
      icon: <HiSupport></HiSupport>,
    }, {
      name: "Information",
      link: "/dashboard/information",
      icon: <HiOutlineClipboardCheck></HiOutlineClipboardCheck>,
    },

  ];
  const adminMenu = [

    {
      name: "Create User",
      link: "/dashboard/user",
      icon: <HiUser></HiUser>
    },

    {
      name: "All User",
      link: "/dashboard/all-user",
      icon: <HiUsers></HiUsers>,
    },

  ];
  useEffect(() => {
    // Enable pusher logging - don't include this in production
    Pusher.logToConsole = false;

    var pusher = new Pusher("89d150e647fb94141319", {
      cluster: "mt1",
    });

    var channel = pusher.subscribe("my-channel");
    channel.bind("my-event", function (datas) {
      start();
      toast.success('New Account Arrived')
      console.log(JSON.stringify(datas));
    });
  }, []);

  useEffect(() => {
    setInterval(function () {
      window.location.reload(true);
    }, 120000);
  }, [])

  let audio = new Audio("/notification.mp3")

  const start = () => {
    audio.play()
  }

  return (
    <section className="flex ">
      <div
        className={`bg-white h-screen max-h-screen overflow-auto px-4 text-btncolor ${open ? "w-72" : "w-14"
          } duration-500`}
      >
        <div className="py-3 flex justify-end">
          <HiMenuAlt1
            size={26}
            className="cursor-pointer"
            onClick={() => setOpen(!open)}
          ></HiMenuAlt1>
        </div>
        <div className="mt-4 flex flex-col gap-4 relative">
          {menus.map((menu, i) => (
            <Link
              key={i}
              to={menu.link}
              className="group flex item-center gap-3 p-2 hover:bg-gray-100 hover:text-btncolor text-menucolor font-bold text-sm"
            >
              <div>{menu.icon}</div>
              <h2
                style={{ transitionDelay: `${i + 3}00ms` }}
                className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                  }`}
              >
                {menu.name}
              </h2>
              <h2
                className={`${open && "hidden"
                  } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}
              >
                {menu.name}
              </h2>
            </Link>
          ))}

          {isAdmin && (
            <>
              {adminMenu.map((menu, i) => (
                <Link
                  key={i}
                  to={menu.link}
                  className="group flex item-center gap-3 p-2 hover:bg-gray-100 hover:text-btncolor text-menucolor font-bold text-sm"
                >
                  <div>{menu.icon}</div>
                  <h2
                    style={{ transitionDelay: `${i + 3}00ms` }}
                    className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                      }`}
                  >
                    {menu.name}
                  </h2>
                  <h2
                    className={`${open && "hidden"
                      } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}
                  >
                    {menu.name}
                  </h2>
                </Link>
              ))}
            </>
          )}
        </div>
        {/* <div className="py-5">
          {
            !isAdmin && <>
              <Link className="group flex item-center gap-3 p-2 hover:bg-gray-100 hover:text-btncolor text-btncolor font-bold text-sm" to={`skype:live:.cid.620c2bdaec6498d5?chat`}>
              <div><FaSkype /></div>
               <h2> Open Skype Profile</h2>
              </Link>
            </>
          }
        </div> */}
      </div>
      <div
        className={`p-3 text-xl bg-mainbg text-white font-semibold ${open ? "w-[calc(100%-18rem)]" : "w-[calc(100%-3.5rem)]"
          }`}
      >
        <Header></Header>
        <Outlet></Outlet>
      </div>
    </section>
  );
};

export default DashboardLayout;
