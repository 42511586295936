import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../Contexts/AuthProvider";
import useToken from "../../hooks/useToken";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const Login = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm();
  const { providerLogin, signIn, createUser, updateUser } = useContext(AuthContext);
  const [loginError, setLoginError] = useState("");
  const [loginUserEmail, setLoginUserEmail] = useState("");
  const [token] = useToken(loginUserEmail);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogin = (data) => {
    fetch(`https://hira-mega.vercel.app/userCount?user=${data.email}`)
      .then(res => res.json())
      .then(userCountResult => {
        if (userCountResult.total === 1) {
          createUser(userCountResult.name.email, userCountResult.name.password)
            .then(result => {

              const userInfo = {
                displayName: userCountResult.name.name
              }
              updateUser(userInfo)
                .then(() => {
                  reset();
                  loginFunc(data.email, data.password);
                })
                .catch(err => toast.error('Email and Password incorrect!'));
            })
            .catch(err => {
              reset();
              loginFunc(data.email, data.password);
            })
        } else {
          toast.error('Email and Password incorrect!');
          reset();
        }
      })

    const loginFunc = (email, password) => {
      setLoginError("");
      signIn(email, password)
        .then((result) => {
          const user = result.user;
          setLoginUserEmail(data.email);
          toast.success("User Logged in Successfully.");
          navigate("/dashboard");
        })
        .catch((error) => {
          toast.error('Email and Password incorrect!');
        });
    }


  };
  return (
    <div className="grid items-center relative w-full h-screen bg-mainbg">
      <div className="w-full md:w-1/4 mx-auto px-4 py-7 bg-white rounded-md text-white">
        <form onSubmit={handleSubmit(handleLogin)}>
          <div className="grid grid-cols-1">
            <div className="px-2">
              <img src="/login.jpg" alt="" className="w-32 h-32 rounded-full mx-auto object-cover" />
              <h2 className="text-left font-semibold text-lg text-headcolor">Login</h2>
              <small className="font-semibold text-xs text-textcolor">Welcome back! Log in to your account.</small>
            </div>
            <div className="text-left px-2 my-2">
              <label className="text-headcolor font-bold text-sm">Email Address</label> <br />
              <input
                type="email"
                placeholder="john@gmail.com"
                {...register("email", {
                  required: "Email is Required",
                })}
                className="bg-transparent border-[1px] border-gray-400 px-6 py-3 rounded-md  w-full text-textcolor focus:outline-none text-sm"
              />
              {errors.email && (
                <p className="text-red-500">{errors.email.message}</p>
              )}
            </div>
            <div className="text-left px-2 my-2">
              <label className="text-headcolor font-bold text-sm">Password</label> <br />
              <input
                type="password"
                placeholder="**********"
                {...register("password", {
                  required: "Password is Required",
                })}
                className="bg-transparent border-[1px] border-gray-400 px-6 py-3 rounded-md  w-full text-textcolor focus:outline-none text-sm"
              />
              {errors.password && (
                <p className="text-red-500">{errors.password.message}</p>
              )}
            </div>
          </div>
          <div className="px-2 mt-2 text-right">
            <button
              type="submit"
              className="bg-btncolor px-8 py-2 rounded-md text-white font-bold drop-shadow-lg text-sm uppercase"
            >
              Sign in
            </button>
          </div>

        </form>
      </div>
    </div>
  );
};

export default Login;
