import React, { useState } from "react";
import { useContext } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { AuthContext } from "../../Contexts/AuthProvider";
import { useEffect } from "react";
import Loader from "../Shared/Loader/Loader";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import useAdmin from "../../hooks/useAdmin";


const Home = () => {
  const { user, logOut } = useContext(AuthContext);
  const [loader, setLoader] = useState(true);
  const [userData, setUserData] = useState();
  const [isAdmin] = useAdmin(user?.email);
  const [rem, setRem] = useState(0);
  const currentDate = new Date();
  const navigate = useNavigate();
  let date1;
  let date2;
  let daysDifference;
  useEffect(() => {
    fetch(`https://hira-mega.vercel.app/users/${user.email}`)
      .then((res) => res.json())
      .then((data) => {
        date1 = data?.currentDate.toString().split('T')[0];
        date2 = currentDate.toISOString().split('T')[0];

        daysDifference = calculateDateDifference(date1, date2);
        setRem(parseInt(daysDifference));
        if (data.role === 'user') {
          if (parseInt(daysDifference) > 30) {
            fetch(`https://hira-mega.vercel.app/users/${data?._id}?email=${data?.email}`, {
              method: "DELETE"
            })
              .then((res) => res.json())
              .then((data) => {
                if (data.deletedCount > 0) {
                  logOut()
                    .then(() => { })
                    .catch(err => console.log(err));
                  navigate('/login');
                }
              });
          }
        }




      });
  }, []);

  useEffect(() => {
    fetch(`https://hira-mega.vercel.app/dashboard?user=${user.email}`)
      .then((res) => res.json())
      .then((data) => {
        setUserData(data);
        setLoader(false);
      });
  }, [user.email]);


  function calculateDateDifference(dateString1, dateString2) {
    const startDate = new Date(dateString1);
    const endDate = new Date(dateString2);
    const differenceInMilliseconds = endDate - startDate;
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    return differenceInDays;
  }
  // console.log(userData);

  if (loader) {
    return <Loader></Loader>;
  }
  const CustomTooltip = ({ active, label, payload }) => {
    if (active && payload && payload.length) {
      const dataPoint = payload[0]; // Assuming only one line on the chart
      return (
        <div className="custom-tooltip bg-white text-textcolor  p-4 rounded-md text-sm">
          <p>User: {label}</p>
          <p>Information: {dataPoint.value}</p>
        </div>
      );
    }
    return null;
  };
  return (
    <div className="w-4/5 md:w-full overflow-x-scroll md:overflow-auto pb-32">
      <div>
        <div className="w-full px-3 py-4 bg-white rounded-md text-btncolor text-sm">অ্যাকাউন্ট নামার পর অবশ্যই তথ্য সেভ করে রাখবেন, নতুন ভিডিও লিঙ্ক যোগ করা হইসে</div>
        {!isAdmin && <h3 className="my-3 text-green-600">You have left <span className="font-bold text-2xl text-orange-400"> {30 - rem}</span> days</h3>}

        <h3 className="my-3 text-headcolor text-base">Welcome, {user?.displayName}</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 my-3">
          {/* {isAdmin && <div className="p-4 flex justify-between rounded-md bg-white text-lg text-btncolor font-semibold">
            <h2>Informations</h2>
            <p>
              {userData[0].informations[0]
                ? userData[0].informations[0]?.count
                : 0}
            </p>
          </div>} */}
          <div className="p-4 flex justify-between rounded-md bg-white text-lg text-btncolor font-semibold">
            <h2>Informations</h2>
            <p>
              {userData[0].informations[0]
                ? userData[0].informations[0]?.count
                : 0}
            </p>
          </div>

          <div className="p-4 flex justify-between rounded-md bg-white text-lg text-btncolor font-semibold">
            <h2>Websites</h2>
            <p>{userData[1].websites[0]?.count}</p>
          </div>
          <div className="p-4 flex justify-between rounded-md bg-white text-lg text-btncolor font-semibold">
            <h2>Total Click </h2>
            <p>
              {userData[2].clicks[0]?.mobileClick +
                userData[2].clicks[0]?.desktopClick}
            </p>
          </div>
          <div className="p-4 flex justify-between rounded-md bg-white text-lg text-btncolor font-semibold">
            <h2>Mobile </h2>
            <p>{userData[2].clicks[0]?.mobileClick}</p>
          </div>
          <div className="p-4 flex justify-between rounded-md bg-white text-lg text-btncolor font-semibold">
            <h2>Desktop</h2>
            <p> {userData[2].clicks[0]?.desktopClick}</p>
          </div>
        </div>

        {isAdmin ?
          <div>
            <h2 className="text-headcolor mb-3 text-base">Information By User</h2>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={userData[3].userCount}
                margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
              >
                <Line
                  type="monotone"
                  dataKey="totalInformation"
                  stroke="#999"
                />
                <CartesianGrid stroke="#1e2f65" strokeDasharray="5 5" />
                <XAxis dataKey="_id.name" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
              </LineChart>
            </ResponsiveContainer>
          </div>
          :
          <></>
        }




      </div>
    </div>
  );
};

export default Home;
