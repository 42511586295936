// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyBqrXzZnYCWnhIYghk5Ay2T43ftZppj4q4",
  authDomain: "hira-mega-server.firebaseapp.com",
  projectId: "hira-mega-server",
  storageBucket: "hira-mega-server.appspot.com",
  messagingSenderId: "243703913788",
  appId: "1:243703913788:web:48eb9b3f9dada0a22c1239"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default app;